import axios from 'axios';
import dayjs from 'dayjs';
import { ElMessage } from 'element-plus';
import type { UploadRawFile } from 'element-plus';
import { uploadFileAPI } from '@/api/common';
import { postDownloadUrl } from '@/api/download';

export const isDateStr = (date: string) => /\d{4}-\d{2}-\d{2}/.test(date);

/**
 * 把字符用指定字符填充
 * @param str 待格式化内容
 * @param symbol 符号
 * @example
 *  const month = (new Date()).getMonth() // -> 8
 *  const monthFull = stringPad(month, '0') // -> '08'
 */
export const stringPad = (str: string | number, symbol: string = '0', len: number = 2) =>
  (symbol.repeat(len) + str).slice(-len);

/**
 * 判断是否跨年
 * @param range 日期字符串范围 ['2020-12xxx', '2021-12xxx']
 */
export const isYearCross = (range: string[]): boolean => {
  const a = range[0].match(/\d{4}/);
  const b = range[1].match(/\d{4}/);
  if (a !== null && b !== null) {
    return a[0] !== b[0];
  }
  return true;
};

/**
 * 为空则返回空，否则返回其它结果
 * @param a 字段值
 * @param b 待返回值
 * @example
 *  const a = a === '' ? '' : `${a}_test` // before
 *  const a = handleEmptyString(a, `${a}_test`)
 */
export const handleEmptyString = (a: string, b: any) => (a === '' ? '' : b);

/**
 * 千分位数字格式，小数点不格式化
 * https://mrluo.life/article/detail/115
 * @param num  待格式化数值
 * @param symbol 连接符
 * @example
 *  formatNumber('12345.1234') // 12,345.1234
 */
export const formatNumber = (num: string | number, symbol: string = ','): string => {
  if (num === '' || num === undefined || num === null) return '';
  const nums = num.toString().split('.');
  let intPart = nums[0];
  const digitPart = nums[1];
  let ret = '';
  while (intPart.length > 3) {
    ret = `${symbol}${intPart.slice(-3)}${ret}`;
    intPart = intPart.slice(0, intPart.length - 3);
  }
  if (intPart) {
    ret = intPart + ret;
  }

  return ret + (digitPart ? `.${digitPart}` : '');
};
/**
 * 上传文件前检查文件类型
 * @param file
 * @param accept string ".zip"
 */
export const checkFileExt = (file: File, accept: string): boolean => {
  // accept设置为.zip居然可以选择其它文件，所以通过文件后缀验证
  const fileName = file.name;
  const pos = fileName.lastIndexOf('.');
  const lastName = fileName.substring(pos, fileName.length);
  if (accept !== lastName) {
    return false;
  }
  return true;
};

/**
 * 下载 ${fileUrl} 对应的资源
 * 注意：在chrome下，如果资源不是同源的，download属性是无效的
 * @param fileName
 * @param fileUrl
 */
export const downloadLinkFile = (fileName: string, fileUrl: string) => {
  const link = document.createElement('a');
  const extensions = fileUrl.split('.').pop();

  console.log('%c [ fileUrl ]-94', 'font-size:13px; background:pink; color:#bf2c9f;', fileUrl);
  fetch(fileUrl)
    .then((res) => res.blob())
    .then((blob) => {
      // 将链接地址字符内容转变成blob地址
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}.${extensions}`;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
    });
};
// eslint-disable-next-line import/no-mutable-exports
let projectInfo: ProjectInfo;

export const setProjectInfo = (value: ProjectInfo) => {
  projectInfo = value;
};

// 导出方法 - 因为名字原因，目前仅支持订单模块的导出
// fileName: 一级菜单名称_二级菜单_20020312年月日+五位不重复随机数
export const downLoad = (url: string, filePre: string = '') => {
  const fileName = `${filePre}_${dayjs().format('YYYYMMDD')}${Math.round(Math.random() * 100000)}`;
  axios({
    method: 'get',
    url,
    responseType: 'blob'
  }).then((response) => {
    const link = document.createElement('a');
    const blob = new Blob([response.data], { type: response.data.type });
    const url1 = URL.createObjectURL(blob);
    link.href = url1;
    link.download = fileName as string;
    link.click();
  });
};

// 下载事件
export const handleDownload = async (val: Download.DownloadItem) => {
  const { tenantId, businessId, businessType } = val || {};
  const res = await postDownloadUrl({
    tenantId,
    businessId,
    businessType
  });
  const { code, data } = res || {};
  if (`${code}` === '0' && data) {
    const { url } = data || {};
    downLoad(url);
  }
};
// element-ui 自定义上传图片
export const httpRequest = (item: any) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('multipartFile', item.file);
    formData.append('fileType', 'image');
    formData.append('tenantId', '1');
    uploadFileAPI(formData)
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });

// 上传office文件
export const httpRequestOffice = (item: any) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('multipartFile', item.file);
    formData.append('fileType', 'office');
    formData.append('tenantId', '1');
    uploadFileAPI(formData)
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
export { projectInfo };

export const isXls = (type: string) =>
  type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
  type === 'application/vnd.ms-excel';

export const isCsv = (type: string) => type === 'text/csv';

// 手机号或座机号
export const reg_tel_mobile = /(^((\+86)|(86))?(1[3-9])\d{9}$)|(^(0\d{2,3})-?(\d{7,8})$)/;

// 获取部门文案
export const getDepartmentText = (department: Basic.Department | undefined) => {
  const textArr = [];
  while (department) {
    textArr.push(department.departmentName);
    department = department.next;
  }
  return textArr.join('-');
};

// 获取图片宽高信息
export const getImageInfo = (file: UploadRawFile) => {
  console.log('%c [ file ]-174', 'font-size:13px; background:pink; color:#bf2c9f;', file);
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    const imgElement = document.createElement('img');
    imgElement.src = URL.createObjectURL(file);
    imgElement.addEventListener('load', () => {
      resolve({
        width: imgElement.width,
        height: imgElement.height
      });
    });
    imgElement.addEventListener('error', () => {
      reject();
    });
  });
};

// 检查上传内容
export const getCheckUpload =
  (checkUploadOption: CheckUploadOption) => (rawFile: UploadRawFile) => {
    const {
      type,
      allowType = [],
      typeErrorMessage = '类型检查错误',
      sizeLimit = Infinity,
      sizeErrorMessage = '',
      widthLimit = [-Infinity, Infinity],
      heightLimit = [-Infinity, Infinity],
      widthHeightErrorMessage = ''
    } = checkUploadOption;
    if (allowType.length > 0 && !allowType.includes(rawFile.type)) {
      ElMessage({
        showClose: true,
        type: 'error',
        message: typeErrorMessage
      });
      return false;
    }
    const sizeM = rawFile.size / 1024 / 1024;
    if (sizeM > sizeLimit) {
      ElMessage({
        showClose: true,
        type: 'error',
        message: sizeErrorMessage
      });
      return false;
    }
    if (type === 'image') {
      return getImageInfo(rawFile).then(
        (imgInfo) =>
          new Promise((resolve, reject) => {
            const { width, height } = imgInfo;
            const widthMin = widthLimit[0];
            const widthMax = widthLimit[1];
            const heightMin = heightLimit[0];
            const heightMax = heightLimit[1];
            console.log('%c [  ]-286', 'font-size:13px; background:pink; color:#bf2c9f;');
            console.log(widthMin, widthMax, width, heightMax, heightMin, height);
            if (
              !(
                width >= widthMin &&
                width <= widthMax &&
                height >= heightMin &&
                height <= heightMax
              )
            ) {
              ElMessage({
                showClose: true,
                type: 'error',
                message: widthHeightErrorMessage
              });
              reject();
            }
            resolve(true);
          }),
        () => {
          ElMessage({
            showClose: true,
            type: 'error',
            message: '图片解析失败'
          });
          return Promise.reject();
        }
      );
    }
    return true;
  };

// 上传文件超出限制
export const uploadExceed = () => {
  ElMessage({
    showClose: true,
    type: 'error',
    message: '超出上传数量限制'
  });
};
export const getSystemId = () => {
  const systemId = process.env.VUE_APP_SYSTEM_ID;
  return systemId ? parseInt(systemId, 10) : null;
};

export const AppEnv = {
  // 判断是否是生产环境
  get isProduction() {
    return process.env.VUE_APP_ENV === 'production';
  },
  // 判断是否是测试环境
  get isTest() {
    return process.env.VUE_APP_ENV === 'test';
  },
  // 判断是否是开发环境
  get isDevelopment() {
    return process.env.VUE_APP_ENV === 'development';
  },

  get appFileHost() {
    return process.env.VUE_APP_FILE_HOST;
  }
};

// 格式化系统和项目，格式化为级联的接口
// 级联不支持key不同，所以需要手动格式化 成label，value，children 的格式。
export const formatSystemProject = (systems: any) =>
  // 第一级是系统 对应的 value: sid, label: sysName, children: projects
  // 第二级是项目 对应的 value: projectId label: projectName children: null
  systems.map((system: any) => {
    const { sid, sysName, projects } = system;
    const children = projects.map((project: any) => {
      const { projectId, projectName } = project;
      return {
        value: projectId,
        label: projectName
      };
    });
    return {
      value: sid,
      label: sysName,
      children
    };
  });
