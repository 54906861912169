const LayoutView = () => import('@/components/layout/Index.vue');
const UserHome = () => import('@/pages/user/index.vue');

export default [
  {
    path: '/user',
    component: LayoutView,
    meta: {
      title: '用户管理'
    },
    redirect: '/user/index',
    children: [
      {
        path: 'index',
        component: UserHome,
        meta: {}
      }
    ]
  }
];
