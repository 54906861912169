import api from './request';

// 查询用户的项目
export const getLoginProjects = () =>
  api.post<{
    loginProject: Project.ProjectItem[];
  }>('/auth-admin/auth/loginProject', {});

// 我的权限
export const getMyAuth = (projectId: number) =>
  api.post<MyAuth.MyAuthDetail>('/auth-admin/auth/getMyAuth', { projectId });

// 申请权限
export const applyAuth = (data: MyAuth.ApplyData) =>
  api.post<any>('/auth-admin/auth/applyAuth', data);

// 我的权限
export const getSysAndProject = (sid: number | null = null) =>
  api.post<any>('/auth-admin/auth/getSysAndProject', { sid });

// 所有权限和项目
export const getAllSysAndProject = (sid: number | null = null) =>
  api.post<any>('/auth-admin/auth/getSysAndAllProject', { sid });
