const LayoutView = () => import('@/components/layout/Index.vue');
const SystemHome = () => import('@/pages/system/index.vue');

export default [
  {
    path: '/system',
    component: LayoutView,
    meta: {
      title: '项目管理',
      icon: 'content'
    },
    redirect: '/system/index',
    children: [
      {
        path: 'index',
        component: SystemHome,
        meta: {
          title: '',
          icon: 'content'
        }
      }
    ]
  }
];
