import { defineStore } from 'pinia';
import { getUserSystems } from '@/api/common';

interface State {
  sid: number;
  projectId: number;
  // 加载状态 0未加载; 1加载中; 2已加载
  loadStatus: number;
  systems: ApiCommon.UserSystem[];
}

// 权限相关 store
const useAuthorityStore = defineStore('authorityStore', {
  state: (): State => ({
    sid: 0,
    projectId: -1,
    loadStatus: 0,
    systems: []
  }),
  actions: {
    updateSystemId(sid: number) {
      this.sid = sid;
    },
    updateProjectId(projectId: number) {
      this.projectId = projectId;
      this.loadStatus = 0;
    },
    // 获取登录用户拥有的系统
    async tryLoadUserSystems() {
      if (this.systems.length > 0) {
        return;
      }
      const data = await getUserSystems(this.sid);
      this.systems = data.data.systems;
    }
  }
});

export { useAuthorityStore };
