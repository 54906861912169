const LayoutView = () => import('@/components/layout/Index.vue');
const ProjectHome = () => import('@/pages/project/index.vue');

export default [
  {
    path: '/project',
    component: LayoutView,
    meta: {
      title: '项目管理',
      icon: 'content'
    },
    redirect: '/project/index',
    children: [
      {
        path: 'index',
        name: 'ProjectHome',
        component: ProjectHome,
        meta: {
          title: '',
          icon: 'content'
        }
      }
    ]
  }
];
