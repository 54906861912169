import { createRouter, createWebHistory } from 'vue-router';
// import { cloneDeep, get } from 'lodash-es';
// import { projectInfo } from '@/utils/index';
import { useUserStore } from '@/store';
import { asyncRoutes, constRoutes, rearRoute } from './routes';
// import { getUserProjectResources } from '@/api/common';

export const activeRule = 'authority-management';

// const closeAuth =
//   process.env.VUE_APP_ENV === 'development' && process.env.VUE_APP_CLOSE_AUTH === 'true';

const router = createRouter({
  history: createWebHistory('/'),
  // routes: closeAuth ? [...asyncRoutes, ...constRoutes, rearRoute] : constRoutes
  routes: [...asyncRoutes, ...constRoutes, rearRoute]
});

router.beforeEach(async (to, from, next) => {
  const unCheckRouteName = ['404', 'login'];
  if (unCheckRouteName.includes(to.name as string)) {
    next();
    return;
  }
  console.log(to, from);
  const userStore = useUserStore();
  const menuList = await userStore.getMenuList;
  if (menuList.includes(to.path)) {
    next();
  } else {
    next('/404');
  }
});

/** @ts-ignore */
// router.beforeEach(async (to, from, next) => {
//   console.log('beforeEach:', to.path);

//   const userStore = useUserStore();
//   const systemStore = useSystemStore();

//   // 贮存项目信息到pinia
//   if (!systemStore.projectInfo) {
//     systemStore.$patch((state) => {
//       state.projectInfo = projectInfo;
//     });
//   }

//   const { name: projectName, projectId } = systemStore.projectInfo || {};

//   if (to.meta?.title) {
//     document.title = `${projectName ? `${projectName}-` : ''}${to.meta.title}`;
//   } else {
//     document.title = `${projectName ? `${projectName}` : ''}`;
//   }

//   // 本地开发调试绕开权限
//   if (process.env.VUE_APP_ENV === 'development' && process.env.VUE_APP_CLOSE_AUTH === 'true') {
//     next();
//     return;
//   }

//   // 需要获取权限资源
//   if (!userStore.hasInitedAuth && projectId) {
//     const res = await getUserProjectResources(projectId);
//     const { code, data } = res as any;
//     if (`${code}` === '0') {
//       userStore.updateAuth(data.resources);
//     }
//   }

//   if (userStore.addRoutes.length === 0) {
//     generateRoute(asyncRoutes, rearRoute, userStore.appAuthList);
//     // addRoute后立刻next(),路由还未初始化完成，会加载不出路由页面组件
//     next({ ...to, replace: true });
//   } else if (allAsyncRoutePaths.includes(to.path) && !resourceMap.has(to.path)) {
//     // next({
//     //   name: '401'
//     // });
//     next();
//   } else {
//     next();
//   }
// });

const allAsyncRoutePaths: string[] = [];
// const resourceMap = new Map<string, AuthResourceItem>();

// 根据后端返回权限资源，对预设路由进行过滤，再动态载入
// export const generateRoute = (
//   asyncRoutes: any[],
//   rearRoute: any,
//   authResourceList: AuthResourceItem[]
// ) => {
//   const cloneAsyncRoutes = cloneDeep(asyncRoutes);
//   const cloneAuthResourceList = cloneDeep(authResourceList);
//   makeFullPath(cloneAsyncRoutes, '');
//   console.log('cloneAsyncRoutes:', cloneAsyncRoutes);
//   generateAuthResourceMap(cloneAuthResourceList);
//   console.log('resourceMap:', resourceMap);
//   filterAddRoutes(cloneAsyncRoutes, resourceMap);
//   // 加入兜底路由
//   router.addRoute(rearRoute);
// };

// 嵌套路由路径进行扁平化，用于匹配，原因是权限中心配置的是全量的路径(除去一级菜单)
// 后端配置的url:/project/index ,所以前端fullPath = /project/index
// const makeFullPath = (routes: any[], parentPath: string) => {
//   routes.forEach((route) => {
//     // 防止出现 //project的情况
//     if (!route.meta) {
//       route.meta = {};
//     }
//     if (route.path === '/') {
//       route.meta.fullPath = '/';
//       allAsyncRoutePaths.push(route.meta.fullPath);
//     } else {
//       const newPath = route.path[0] === '/' ? route.path.substring(1) : route.path;
//       route.meta.fullPath =
//         route.path[0] === '/' ? `${parentPath}${newPath}` : `${parentPath}/${newPath}`;
//       allAsyncRoutePaths.push(route.meta.fullPath);
//     }

//     if (route.children && route.children.length > 0) {
//       makeFullPath(route.children, route.meta.fullPath);
//     }
//   });
// };

// 把权限资源变成map结构，方便查询
// const generateAuthResourceMap = (authResourceList: AuthResourceItem[]) => {
//   authResourceList.forEach((resource) => {
//     // 后端配置的url:/project  ====> formatUrl = /project
//     // const formatUrl = `/${resource.url.split('/').slice(2).join('/')}`;
//     // resource.url = resource.url;
//     resourceMap.set(resource.url, resource);
//     if (resource.children && resource.children.length > 0) {
//       generateAuthResourceMap(resource.children);
//     }
//   });
// };

// // 过滤掉权限外的路由，添加有权限的路由
// const filterAddRoutes = (routes: any[], resourceMap: Map<string, AuthResourceItem>) => {
//   const addRoutes: any[] = [];
//   for (let i = 0; i < routes.length; i++) {
//     // 合法的
//     const validRoute = getValidRoute(routes[i], resourceMap);
//     if (!isEqual(validRoute, {})) {
//       // @ts-ignore
//       router.addRoute(validRoute);
//       addRoutes.push(validRoute);
//     }
//   }
//   console.log('addRoutes:', addRoutes);
//   const userStore = useUserStore();
//   userStore.$patch((state) => {
//     state.addRoutes = addRoutes;
//   });
// };

// const getValidRoute = (route: any, resourceMap: Map<string, AuthResourceItem>) => {
//   let validRoute: any = null;
//   const fullPath = get(route, 'meta.fullPath', '');
//   if (resourceMap.get(fullPath) || fullPath === '/') {
//     validRoute = cloneDeep(route);
//     delete validRoute.children;
//     if (validRoute.meta && resourceMap.has(fullPath)) {
//       validRoute.meta.title = resourceMap.get(fullPath)!.name;
//     }
//     if (route.children && route.children.length) {
//       route.children.forEach((child: any) => {
//         const res = getValidRoute(child, resourceMap);
//         if (res) {
//           validRoute.children = (validRoute.children || []).concat(res);
//           validRoute.redirect = validRoute.children[0].meta.fullPath;
//         }
//       });
//     }
//   }
//   return validRoute;
// };

export default router;
