import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import '@/style/element-variables.scss';
import '@/style/global.scss';
import locale from 'element-plus/lib/locale/lang/zh-cn';

// EUI 需在 ElementUI 之后引入
import EUI from '@aciga/e-ui-next';
import '@aciga/e-ui-next/dist/theme-chalk/css/index.css';

import * as ElementPlusIconsVue from '@element-plus/icons-vue';
// import { setProjectInfo } from '@/utils/index';

import { LDOssHelper } from '@njld/oss-helper';
import { AppEnv } from '@/utils/index';

import router from './router';
import pinia from './store';
import App from './App.vue';

LDOssHelper.setBaseUrlString(AppEnv.appFileHost ?? '');

function render() {
  // @ts-ignore
  const app = createApp(App);
  app.use(ElementPlus, { locale, size: 'small', zIndex: 3000 });
  app.use(EUI, undefined);
  app.use(pinia);
  app.use(router);

  app.mount('#app');

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
  }

  return app;
}

render();
