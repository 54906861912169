const LayoutView = () => import('@/components/layout/Index.vue');
const RoleHome = () => import('@/pages/role/index.vue');

export default [
  {
    path: '/role',
    component: LayoutView,
    meta: {
      title: '角色管理',
      icon: 'content'
    },
    redirect: '/role/index',
    children: [
      {
        path: 'index',
        component: RoleHome,
        meta: {
          title: '',
          icon: 'content'
        }
      }
    ]
  }
];
