const LayoutView = () => import('@/components/layout/Index.vue');
const ResourceHome = () => import('@/pages/resource/index.vue');

export default [
  {
    path: '/resource',
    component: LayoutView,
    meta: {
      title: '资源管理'
    },
    redirect: '/resource/index',
    children: [
      {
        path: 'index',
        component: ResourceHome,
        meta: {
          title: ''
        }
      }
    ]
  }
];
