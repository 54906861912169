import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import qs from 'qs';
import { ElMessage as Message } from 'element-plus';
import { env } from './config';

/**
 * @description 自定义逻辑封装：后端子服务接口code处理
 * @param {*} code
 * @param {*} msg
 */
const handleCode = (code: number, msg: string) => {
  // code 封装.....
  // if (`${code}` !== '0') {
  Message({
    showClose: true,
    type: 'error',
    message: msg.replace(/\[.+\]/, '') || `后端接口${code}异常`
  });
  // }
};

/**
 * 请求正常服务端返回 code 处理
 * 接口需分 upc、相应后端服务 两种服务接口，UPC接口字符串含v1，通过v1 区别
 * @param {*} res
 */
const checkCode = (res: any) => {
  if (res.status === 200) {
    if (res.data && !res.data?.code) {
      return Promise.resolve(res);
    }
    handleCode(res.data.code, res.data.message);
    return Promise.reject(res);
  }
  return Promise.reject(res);
};

/**
 * 请求异常 status 处理
 * @param {*} res
 */
const checkStatus = (error: { message: string }) => {
  let { message } = error;
  // 401 且不是 开发环境 则跳转
  const path = window.location.pathname;

  if (message.includes('401') && !path.startsWith('/login')) {
    window.open(
      `${window.location.origin}/login?redirectUrl=${encodeURI(window.location.href)}`,
      '_self'
    );
  }
  if (message.includes('401')) {
    message = '您的授权已过期或未授权，请授权后访问。';
  } else if (message === 'Network Error') {
    message = '后端接口连接异常';
  } else if (message.includes('timeout')) {
    message = '后端接口请求超时';
  } else if (message.includes('Request failed with status code')) {
    const code = message.substr(message.length - 3);
    message = `后端接口${code}异常`;
  }
  Message({
    showClose: true,
    type: 'error',
    message
  });

  return Promise.reject(error);
};

interface IRequestConfig extends AxiosRequestConfig {
  otherConfig?: any;
}

/**
 * 兼容 contentType 类型
 * @param {*} config
 */
const checkContentType = (config: IRequestConfig) => {
  if (
    Object.prototype.toString.call(config.data) !== '[object FormData]' &&
    config.headers['Content-Type'] === 'application/x-www-form-urlencoded;charset=UTF-8'
  ) {
    config.data = qs.stringify(config.data);
  }
  if (config.otherConfig) {
    config = { ...config, ...config.otherConfig };
  }
  return config;
};

const instance = axios.create({
  baseURL: `${env.apiBase}/gw/api`,
  timeout: 5000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
});

// 请求拦截处理
// 请求拦截处理
instance.interceptors.request.use(
  (config) => {
    // @ts-ignore， 处理上传baseUrl
    config.params = {
      apiTenant: 6
    };
    return checkContentType(config);
  },
  (error) => Promise.reject(error)
);

// 响应拦截处理
instance.interceptors.response.use(
  (response) => checkCode(response),
  (error) => checkStatus(error)
);
type HandRes<T> = {
  code: number;
  message: string;
  data: T;
};

function Request<T = unknown>(configParam: AxiosRequestConfig): Promise<HandRes<T>> {
  return new Promise((resolve, reject) => {
    instance
      .request<T, AxiosResponse<HandRes<T>>>(configParam)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
}
export default {
  // 抛出axios 实例方便扩展
  request: instance,
  /**
   * GET 请求
   * @param {*} url
   * @param {*} params
   * @param {*} headers
   */
  get<T>(
    url: string,
    params: object,
    headers = {},
    otherBase: 'apiBase' | 'logoutBase' = 'apiBase'
  ) {
    if (otherBase !== 'apiBase') {
      return Request<T>({
        baseURL: env[otherBase],
        url,
        method: 'GET',
        params,
        headers
      });
    }
    return Request<T>({
      url,
      method: 'GET',
      params,
      headers
    });
  },

  /**
   * POST请求
   * @param {*} url
   * @param {*} data
   * @param {*} headers
   */
  post<T>(url: string, data: object, headers = {}, otherOptions = {}) {
    const requsetConfig = {
      url,
      method: 'POST' as 'POST',
      data,
      headers,
      ...otherOptions
    };
    return Request<T>(requsetConfig);
  },

  /**
   * 上传请求
   * fixme: 上传下载和接口应该用不同的instance
   * @param {*} url
   * @param {*} formdata
   * @param {*} headers
   */
  upload(
    url: string,
    formdata: object,
    headers = {
      'Content-Type': 'multipart/form-data'
    }
  ) {
    return instance({
      url: env.apiBase + url,
      method: 'POST',
      headers,
      data: formdata
    });
  },

  /**
   * 下载请求
   * fixme: 上传下载和接口应该用不同的instance
   * @param {*} url
   * @param {*} data
   * @param {*} headers
   * @param {*} method
   */
  download(url: string, data: object, headers?: any, method?: Method) {
    return instance({
      url,
      method,
      responseType: 'blob',
      transformResponse: [(res) => res],
      data,
      headers
    });
  }
};
