import { defineStore } from 'pinia';

interface State {
  // 当前用户有权限的项目
  projectInfo: ProjectInfo | null | any;
  //
}

export const useSystemStore = defineStore('system', {
  state: (): State => ({
    projectInfo: {
      projectId: 37
    }
  }),
  actions: {
    setProjectInfo(projectInfo: any) {
      this.projectInfo = projectInfo;
    }
  }
});
