const LayoutView = () => import('@/components/layout/Index.vue');
const MyAuth = () => import('@/pages/myAuth/index.vue');
const ApplyAuth = () => import('@/pages/myAuth/apply.vue');

export default [
  {
    path: '/myAuth',
    component: LayoutView,
    redirect: '/myAuth/index',
    meta: {
      title: '我的权限',
      icon: 'content'
    },
    children: [
      {
        path: 'index',
        component: MyAuth,
        meta: {
          title: '',
          icon: 'content'
        }
      },
      {
        path: 'apply',
        component: ApplyAuth,
        meta: {
          title: '',
          icon: 'content'
        }
      }
    ]
  }
];
