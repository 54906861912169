import api from './request';

// 查询系统
export const getSystemList = (data: SystemRequest.QuerySystemRequest) =>
  api.post<SystemResponse.QuerySystemResponse>('/auth-admin/system/query', data);

// 更新/新增
export const createOrUpdateSystem = (data: SystemRequest.CreateSystemRequest) =>
  api.post<SystemResponse.CreateSystemResponse>('/auth-admin/system/save', data);

// 删除项目
export const deleteSystem = (sidData: SystemRequest.DeleteSystemRequest) =>
  api.post<SystemResponse.DeleteSystemResponse>('/auth-admin/system/delete', sidData);
