const LayoutView = () => import('@/components/layout/Index.vue');
const VirtualGroupHome = () => import('@/pages/virtualGroup/index.vue');

export default [
  {
    path: '/virtualGroup',
    component: LayoutView,
    meta: {
      icon: 'content'
    },
    redirect: '/virtualGroup/index',
    children: [
      {
        path: 'index',
        component: VirtualGroupHome,
        meta: {
          title: '',
          icon: 'content'
        }
      }
    ]
  }
];
