import { defineStore } from 'pinia';
import { get } from 'lodash';
import { getSystemList } from '@/api/system';
import { getSysAndProject } from '@/api/myAuth';
import { formatSystemProject } from '@/utils/index';
import { getUserProjectResources } from '@/api/common';

interface State {
  userInfo: UserInfo | null;
  storeCode: string;
  appAuthList: AuthResourceItem[];
  // 菜单列表
  menuList: MenuItem[];
  authBtnList: AuthResourceItem[]; // 接口按钮级别资源
  addRoutes: any[];
  hasInitedAuth: boolean;
}

function getAuthBtnList(appAuthList: AuthResourceItem[]) {
  let tempQueue = [...appAuthList];
  const resArr: AuthResourceItem[] = [];
  while (tempQueue.length > 0) {
    const item = tempQueue.shift();
    if (item) {
      if (item.children) {
        tempQueue = [...tempQueue, ...item.children];
      }
      // 级别4的资源
      if (item.resourceType === 4) {
        resArr.push(item);
      }
    }
  }
  return resArr;
}
export const useUserStore = defineStore('user', {
  state: (): State => ({
    userInfo: null,
    storeCode: '',
    appAuthList: [],
    menuList: [],
    authBtnList: [],
    addRoutes: [],
    hasInitedAuth: false
  }),
  getters: {
    // 除了可以通过 appAuthList 在项目初始化之后，获取权限树，还存在项目未初始化的时候，需要获取路由信息
    async getMenuList() {
      const menuList: string[] = ['/myAuth/apply'];
      console.log('尝试获取meuList');
      try {
        // 通过sid换projectId
        // 通过projectId换整颗权限树
        // const projectInfo = await getUserProjects(getSystemId());
        // const projectId = projectInfo.data.projects[0].id;
        console.log(process.env.VUE_APP_ENV, process.env.VUE_APP_PROJECT_ID);
        const resourcesList = await getUserProjectResources(
          process.env.VUE_APP_PROJECT_ID ? parseInt(process.env.VUE_APP_PROJECT_ID, 10) : 37
        );

        // 针对返回值做处理，只要二级children内的url。便于和路由跳转的路径匹配
        for (let i = 0; i < resourcesList.data.resources.length; i++) {
          const element = resourcesList.data.resources[i];
          if (Array.isArray(element.children)) {
            for (let j = 0; j < element.children.length; j++) {
              const subMenuInfo = element.children[j];
              menuList.push(subMenuInfo.url);
            }
          }
        }
        console.log(menuList);
        return menuList;
      } catch (e) {
        return menuList;
      }
    }
  },
  actions: {
    setUser(userInfo: any) {
      this.userInfo = userInfo;
      const storeCode = get(userInfo, 'relatedDTOList[0].relatedCode', '');
      this.storeCode = storeCode;
    },
    updateAuth(authResourceList: AuthResourceItem[]) {
      // 当前子应用权限
      if (Array.isArray(authResourceList)) {
        this.appAuthList = authResourceList;
        this.hasInitedAuth = true;
        // 只记录了一级菜单，二级菜单有需要后面再迭代补充吧。
        this.menuList = authResourceList.map((item) => ({
          resourceId: item.id,
          projectId: item.projectId,
          resourceName: item.name,
          resourceType: 'menu',
          resourceTag: item.url, // 目前先采用resourceUrl，可能后续调整
          resourceUrl: item.url,
          menuClass: '',
          newWindow: item.operateType,
          parentId: item.parentId,
          isShow: 1,
          isPublic: 0,
          sortId: 1,
          addTime: item.createTime,
          icon: item?.icon
        }));
        // 按钮
        this.authBtnList = getAuthBtnList(this.appAuthList);
      }
    },
    getSystemProjectsTree(sid: number | null = null) {
      return getSysAndProject(sid).then((res) => {
        const ddd = formatSystemProject(res.data.systems);
        return ddd;
      });
    },
    systemList() {
      const queryAllSystemParams = {
        name: '',
        paging: {
          pageSize: 100,
          pageNum: 1
        }
      };
      return getSystemList(queryAllSystemParams)
        .then((res) => res.data.systems)
        .catch(() => [] as SystemServerDto.SystemDto[]);
    }
  }
});
