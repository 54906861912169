export const env: {
  apiBase: string;
  fileBase: string;
  logoutBase: string;
  context: string;
} = {
  // 当前环境
  context: process.env.VUE_APP_ENV || '',
  apiBase: process.env.VUE_APP_API_HOST || '',
  logoutBase: process.env.VUE_APP_LOGOUT_HOST || '',
  fileBase: process.env.VUE_APP_FILE_HOST || ''
};
