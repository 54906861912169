import myAuthRouter from './modules/myAuth';
import projectRouter from './modules/project';
import systemRouter from './modules/system';
import resourceRouter from './modules/resource';
import roleRouter from './modules/role';
import userRouter from './modules/user';
import virtualGroupRouter from './modules/virtualGroup';
import downloadList from './modules/downloadList';

const NoRightsPage = () => import('@/pages/noRightsPage/index.vue');
const NoFoundPage = () => import('@/pages/errorPage/404.vue');
const LoginPage = () => import('@/pages/login/index.vue');

// 动态路由，配合后端权限设置
export const asyncRoutes = [
  {
    path: '/',
    redirect: '/myAuth',

    meta: {
      title: '权限中心',
      hidden: true
    },
    children: [
      ...myAuthRouter,
      ...systemRouter,
      ...projectRouter,
      ...resourceRouter,
      ...roleRouter,
      ...userRouter,
      ...virtualGroupRouter,
      ...downloadList
    ]
  }
];

// 可直接注册静态路由
export const constRoutes = [
  {
    path: '/401',
    name: '401',
    component: NoRightsPage,
    meta: {
      title: '暂无权限',
      hidden: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {
      title: '登录',
      hidden: true
    }
  }
];

// 兜底路由
export const rearRoute = {
  path: '/:w+',
  name: '404',
  component: NoFoundPage,
  meta: {
    title: '404',
    // hidden 表示无需在路由展示
    hidden: true
  }
};
