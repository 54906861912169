import api from './request';

// 下载列表
export const postDownloadList = (sid: number | null = null) =>
  api.post<Download.DownloadItem[]>(
    '/oss/file/getDownloadList',
    { sid },
    {
      type: 'upload'
    }
  );

// 下载
export const postDownloadUrl = (data: {
  tenantId: number;
  businessId: string;
  businessType: string;
}) =>
  api.post<{ date: string; sign: string; url: string }>('/oss/file/getDownloadUrl', data, {
    type: 'upload'
  });
