import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persist';

export * from './modules/user';
export * from './modules/system';
export * from './modules/useLoginUserDetailStore';

const pinia = createPinia();
pinia.use(piniaPersist);

export default pinia;
