const LayoutView = () => import('@/components/layout/Index.vue');
const DownloadList = () => import('@/pages/download/index.vue');

export default [
  {
    path: '/downloadList',
    component: LayoutView,
    redirect: '/downloadList/index',
    meta: {
      title: '我的权限',
      icon: 'content'
    },
    children: [
      {
        path: 'index',
        component: DownloadList,
        meta: {
          title: '',
          icon: 'content'
        }
      }
    ]
  }
];
