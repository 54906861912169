import api from './request';
// import { authResources } from './mock';

export function loginBUserDetail() {
  return api.post<ApiCommon.LoginUserDetailRespData>('user-admin/loginBUserDetail', {});
}

// 获取用户零售中台有权限的子项目
export const getUserProjects = (sid: number | null) =>
  api.post<{ projects: ProjectItem[] }>('/retail/access/control/getUserProjects', { sid });

/**
 * 获取用户项目下所有资源
 * @param
 * @returns
 */
export const getUserProjectResources = (_projectId: number) =>
  api.post<{ resources: AuthResourceItem[] }>('/retail/access/control/getUserProjectResource', {
    projectId: _projectId
  });
// new Promise((resolve) => {
//   setTimeout(() => resolve(authResources), 1000);
// });

export function getResourceSign(data: any) {
  return api.post(
    '/oss/obs-file/getResourceSign',
    data,
    {},
    {
      baseURL: process.env.VUE_APP_OBS_HOST
    }
  );
}

// 上传文件到oss，直接上传。 不走网关。
export function uploadOss(param: any) {
  return fetch(param.url, {
    method: 'POST',
    credentials: 'include',
    body: param.formData ?? {}
  });
}

// 获取OSS签名接口
export function getOssPreviewLink(data: any) {
  return api.post<string>(
    '/oss/obs-file/getUrlByFileKey',
    data,
    {},
    {
      baseURL: process.env.VUE_APP_OBS_HOST
    }
  );
}

// TODO-将要删除的方法 上传文件
export const uploadFileAPI = (requestData: FormData) =>
  api.post<string>('/oss/file/uploadFilePublic', requestData, {
    type: 'upload'
  });

type HandRes = {
  code: number;
  message: string;
  data: {
    page: {
      cursor: number;
    };
    [key: string]: any;
  };
};

// 使用游标重复请求，获得全量数据 （这里用前端分页请求，其实后端做更好，暂时battle 不过）
export const getAllDataWithCursor = async (
  fn: Function,
  params: { [key: string]: any },
  keyField: string
): Promise<HandRes> => {
  const result: HandRes = {
    code: 0,
    message: '',
    data: {
      page: {
        cursor: 0
      },
      [keyField]: []
    }
  };
  params.page = {
    pageSize: 200,
    cursor: 0
  };
  let finish = false;
  try {
    /* eslint-disable no-await-in-loop */
    while (!finish) {
      const httpRes: HandRes = await fn(params);
      const { code, data } = httpRes;
      if (`${code}` === '0' && data) {
        result.data![keyField] = result.data![keyField].concat(data[keyField]);
        if (data.page.cursor === -1) {
          finish = true;
          return result;
        }
        finish = false;
        params.page.cursor = data.page.cursor;
      }
    }
    return result;
  } catch (err) {
    return Promise.reject(err);
  }
};

// 请求日志。 项目、资源、角色、用户、虚拟组五个二级页表单都需要请求
export const queryLog = (requsetData: CommonRequest.queryLogRequest) =>
  api.post<CommonResponse.queryLogResponse>('/auth-admin/auth/queryLog', requsetData);

// 获取登录用户拥有的系统
export function getUserSystems(sid: number | null) {
  return api.post<{ systems: ApiCommon.UserSystem[] }>('/retail/access/control/getUserSystems', {
    sid
  });
}
